import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, } from './styles';

import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function ColegioPlanoHome() {
    const navigate = useNavigate();


    function chamaLinguagemC() {
        navigate('/linguagemc');
    }
    function chamaSistemasOperacionais() {
        navigate('/sistemasoperacionais');
    }



    return (
        <div>
            <Sidebar>
                <h1>Matérias</h1>

                <ButtonContainer>
                    {/* <Button texto='oi' /> */}
                    <Button texto='Funções em C' onClick={chamaLinguagemC} setMarginRight='5px' setMarginBottom='5px' />
                    
                    <Button texto='Sistemas Operacionais' onClick={chamaSistemasOperacionais} setMarginRight='5px' setMarginBottom='5px' />

                </ButtonContainer>
            </Sidebar>
        </div>
    );
}

export default ColegioPlanoHome;


