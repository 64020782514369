import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


import Home from './Pages/Home/Home';
import Welcome from './Pages/Welcome/Welcome';
import FileDownloadButton from './Pages/ScriptD/ScriptD';
import ProgramacaoOrientadaObjetos from './Pages/ProgramacaoOrientadaObjetos/ProgramacaoOrientadaObjetos';
import NotaseTrabalhos from './Pages/NotaseTrabalhos/NotaseTrabalhos';



import HtmlHome from './Pages/PagesHtml/HtmlHome/HtmlHome';
import OqueeHtml from './Pages/PagesHtml/OqueeHtml/Oqueehtml';
import OqueeCss from './Pages/PagesHtml/OqueeCss/Oqueecss';
import BasicoHtml from './Pages/PagesHtml/BasicoHtml/BasicoHtml';
import HtmleCss from './Pages/PagesHtml/HtmleCss/HtmleCss';
import HtmleJavascript from './Pages/PagesHtml/HtmleJavascript/HtmleJavascript';
import HtmlTags from './Pages/PagesHtml/htmlTags/HtmlTags';
import HtmlBotoes from './Pages/PagesHtml/HtmlBotoes/HtmlTags';
import HtmlDisplayFlex from './Pages/PagesHtml/HtmlDisplayFlex/HtmlDisplayFlex';
import HtmlTelaLogin from './Pages/PagesHtml/HtmlTelaLogin/HtmlTelaLogin';
import HtmlTelaCadastro from './Pages/PagesHtml/HtmlTelaCadastro/HtmlTelaCadastro';
import HtmlTabelas from './Pages/PagesHtml/HtmlTabelas/HtmlTabelas';
import HtmlLinksNavegacao from './Pages/PagesHtml/HtmlLinksNavegacao/HtmlLinksNavegacao';
import HtmlImagensMultimidia from './Pages/PagesHtml/HtmlImagensMultimidia/HtmlImagensMultimidia';
import HtmlFormulariosEntradaDados from './Pages/PagesHtml/HtmlFormulariosEntradaDados/HtmlFormulariosEntradaDados';
import HtmlSeletoresAtributos from './Pages/PagesHtml/HtmlSeletoresAtributos/HtmlSeletoresAtributos';
import HtmlExemploSite from './Pages/PagesHtml/HtmlExemploSite/HtmlExemploSite';




import CssHome from './Pages/Css/CssHome/CssHome';
import CssWidthHeight from './Pages/Css/CssWidthHeight/CssWidthHeight';
import CssCorBackgroundCor from './Pages/Css/CssCorBackgroundCor/CssCorBackgroundCor';
import CssHover from './Pages/Css/CssHover/CssHover';
import CssClassPseudoClass from './Pages/Css/CssClassPseudoClass/CssClassPseudoClass';





import PhpHome from './Pages/PagesPHP/PhpHome/PhpHome';
import OqueePhp from './Pages/PagesPHP/BasicoPhp/BasicoPhp';
import PhpCondicoes from './Pages/PagesPHP/PhpCondicoes/PhpCondicoes';
import PhpFuncoes from './Pages/PagesPHP/PhpFuncoes/PhpFuncoes';
import PhpSwitch from './Pages/PagesPHP/PhpSwitch/PhpSwitch';
import PhpFuncoesMatematicas from './Pages/PagesPHP/PhpFuncoesMatematicas/PhpFuncoesMatematicas';
import PhpArraysAssociativosMatrizes from './Pages/PagesPHP/PhpArraysAssociativosMatrizes/PhpArraysAssociativosMatrizes';
import PhpManipulandoArrays from './Pages/PagesPHP/PhpManipulandoArrays/PhpManipulandoArrays';
import PhpForForeach from './Pages/PagesPHP/PhpForForeach/PhpForForeach';
import PhpConexao from './Pages/PagesPHP/PhpConexao/PhpConexao';



import JavascriptHome from './Pages/PagesJavascript/JavascriptHome/HtmlHome';
import OqueeJavascript from './Pages/PagesJavascript/Oqueejavascript/OqueeJavascript';
import JsAdicionarPagina from './Pages/PagesJavascript/JsAdicionarPagina/JsAdicionarPagina';
import JsVariaveis from './Pages/PagesJavascript/JsVariaveis/JsVariaveis';
import JsComparacao from './Pages/PagesJavascript/JsComparacao/JsComparacao';
import JsFuncaoSegundoGrau from './Pages/PagesJavascript/JsFuncaoSegundoGrau/JsFuncaoSegundoGrau';
import JsOperacoesMatematicas from './Pages/PagesJavascript/JsOperacoesMatematicas/JsOperacoesMatematicas';
import JsFuncoesMath from './Pages/PagesJavascript/JsFuncoesMath/JsFuncoesMath';
import JsDom from './Pages/PagesJavascript/JsDom/JsDom';
import JsformularioDom from './Pages/PagesJavascript/JsformularioDom/JsformularioDom';
import JsClasseDom from './Pages/PagesJavascript/JsClasseDom/JsClasseDom';
import JsLocalSessionStorage from './Pages/PagesJavascript/JsLocalSessionStorage/JsLocalSessionStorage';
import JsVetores from './Pages/PagesJavascript/JsVetores/JsVetores';


import SqlHome from './Pages/PagesSql/SqlHome/SqlHome';
import OqueeSql from './Pages/PagesSql/OqueeSql/OqueeSql';
import OqueeMysql from './Pages/PagesSql/OqueeMysql/OqueeMysql';
import SqlCrud from './Pages/PagesSql/SqlCrud/SqlCrud';
import SqlXampp from './Pages/PagesSql/SqlXampp/SqlXampp';
import SqlCriarBancoeTabelas from './Pages/PagesSql/SqlCriarBancoeTabelas/SqlCriarBancoeTabelas';



import ArquiteturaSistemasUmHome from './Pages/ArquiteturaSistemasUm/ArquiteturaSistemasUmHome/ArquiteturaSistemasUmHome';



import EstudosProvas from './Pages/PagesEstudosProvas/EstudosProvas/EstudosProvas';
import ProvasSegundoAno from './Pages/PagesEstudosProvas/ProvasSegundoAno/ProvasSegundoAno';
import ProvasTerceiroAno from './Pages/PagesEstudosProvas/ProvasTerceiroAno/ProvasTerceiroAno';


import ColegioPlanoHome from './Pages/ColegioPlano/ColegioPlanoHome/ColegioPlanoHome';
import ColegioPlanoSistemasOperacionais from './Pages/ColegioPlano/ColegioPlanoSistemasOperacionais/ColegioPlanoSistemasOperacionais';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* http://localhost:3000/scriptd */}
        {/* <Route path="/" element={<Welcome />} />
        <Route path="/login" element={<Login />} /> */}
        <Route path="/" element={<Welcome />} />
        <Route path="/home" element={<Home />} />
        <Route path='/scriptd' element={<FileDownloadButton />} />
        <Route path="/programacaoorientadaobjetos" element={<ProgramacaoOrientadaObjetos />} />



        <Route path="/notasetrabalhos" element={<NotaseTrabalhos />} />




        <Route path="/homejavascript" element={<JavascriptHome />} />
        <Route path="/oqueejavascript" element={<OqueeJavascript />} />
        <Route path="/jsadicionarpagina" element={<JsAdicionarPagina />} />
        <Route path="/jsvariaveis" element={<JsVariaveis />} />
        <Route path="/jscomparacao" element={<JsComparacao />} />
        <Route path="/jsfuncaosegundograu" element={<JsFuncaoSegundoGrau />} />
        <Route path="/jsoperacoesmatematicas" element={<JsOperacoesMatematicas />} />
        <Route path="/jsfuncoesmath" element={<JsFuncoesMath />} />
        <Route path="/jsdom" element={<JsDom />} />
        <Route path="/jsformulariodom" element={<JsformularioDom />} />
        <Route path="/jsclassedom" element={<JsClasseDom />} />
        <Route path="/jssessionlocalstorage" element={<JsLocalSessionStorage />} />
        <Route path="/jsvetores" element={<JsVetores />} />


        <Route path="/homephp" element={<PhpHome />} />
        <Route path="/oqueephp" element={<OqueePhp />} />
        <Route path="/phpcondicoes" element={<PhpCondicoes />} />
        <Route path="/phpfuncoes" element={<PhpFuncoes />} />
        <Route path="/phpswitch" element={<PhpSwitch />} />
        <Route path="/funcoesmatematicas" element={<PhpFuncoesMatematicas />} />
        <Route path="/arraysassociativosmatrizes" element={<PhpArraysAssociativosMatrizes />} />
        <Route path="/phpmanipulandoarrays" element={<PhpManipulandoArrays />} />
        <Route path="/phpforforeach" element={<PhpForForeach />} />
        <Route path="/phpconexao" element={<PhpConexao />} />



        <Route path="/homehtml" element={<HtmlHome />} />
        <Route path="/htmlformulariosentradadados" element={<HtmlFormulariosEntradaDados />} />
        <Route path="/htmlimagensmultimidia" element={<HtmlImagensMultimidia />} />
        <Route path="/htmlbotoes" element={<HtmlBotoes />} />
        <Route path="/htmllinksnavegacao" element={<HtmlLinksNavegacao />} />
        <Route path="/htmltabelas" element={<HtmlTabelas />} />
        <Route path="/htmltelalogin" element={<HtmlTelaLogin />} />
        <Route path="/htmltelacadastro" element={<HtmlTelaCadastro />} />
        <Route path="/htmldisplayflex" element={<HtmlDisplayFlex />} />
        <Route path="/htmltags" element={<HtmlTags />} />
        <Route path="/htmlejavascript" element={<HtmleJavascript />} />
        <Route path="/htmlecss" element={<HtmleCss />} />
        <Route path="/oqueehtml" element={<OqueeHtml />} />
        <Route path="/oqueecss" element={<OqueeCss />} />
        <Route path="/basicohtml" element={<BasicoHtml />} />
        <Route path="/htmlseletoresatributos" element={<HtmlSeletoresAtributos />} />
        <Route path="/htmlexemplosite" element={<HtmlExemploSite />} />
        {/* <Route path="/jsfuncaosegundograu" element={<HtmlSeletoresAtributos />} /> */}


        <Route path="/homecss" element={<CssHome />} />
        <Route path="/csswidthheight" element={<CssWidthHeight />} />
        <Route path="/csscorbackgroundcor" element={<CssCorBackgroundCor />} />
        <Route path="/csshover" element={<CssHover />} />
        <Route path="/cssclasspseudoclass" element={<CssClassPseudoClass />} />


        <Route path="/homesql" element={<SqlHome />} />
        <Route path='/oqueesql' element={<OqueeSql />} />
        <Route path='/oqueemysql' element={<OqueeMysql />} />
        <Route path='/sqlcrud' element={<SqlCrud />} />
        <Route path='/sqlxampp' element={<SqlXampp />} />
        <Route path='/sqlcriarbancosetabelas' element={<SqlCriarBancoeTabelas />} />



        <Route path='/arquiteturasistemasum' element={<ArquiteturaSistemasUmHome />} />


        <Route path='/estudosprovas' element={<EstudosProvas />} />
        <Route path='/estudosprovassegundoano' element={<ProvasSegundoAno />} />
        <Route path='/estudosprovasterceiroano' element={<ProvasTerceiroAno />} />


        <Route path='/colegioplano' element={<ColegioPlanoHome />} />

        <Route path='/sistemasoperacionais' element={<ColegioPlanoSistemasOperacionais />} />
    

      </Routes>
    </BrowserRouter>

  );
}

export default App;